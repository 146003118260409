import React, { useState } from "react";
import data from "../content/Faqs.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/Faqs.scss";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="root-Faqs" id="faqs">
            <div className="header">
                <h2>FAQs</h2>
            </div>

            <div className="content">
                <ul>
                    {data.map((item, index) => (
                        <li key={index}>
                            <div
                                className="question"
                                onClick={() => toggleAnswer(index)}
                            >
                                <h3>{item.question}</h3>
                                {activeIndex === index ? (
                                    <FontAwesomeIcon
                                        icon={faAngleUp}
                                        className="arrowUp"
                                        style={{ fontSize: "1.2rem" }}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faAngleUp}
                                        className="arrowDown"
                                        style={{ fontSize: "1.2rem" }}
                                    />
                                )}
                            </div>

                            <div
                                className={
                                    activeIndex === index
                                        ? "answerActive"
                                        : "answerInactive"
                                }
                            >
                                {item.answer && <p>{item.answer}</p>}
                                {item.point && (
                                    <ul className="points">
                                        {item.point.map((point, idx) => (
                                            <li key={idx}>
                                                <strong>{point.title}</strong>:{" "}
                                                {point.desc}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default FAQ;
