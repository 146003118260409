import React from "react";
import blogsData from "../content/Blogs.json"; // Assuming your JSON file is imported here
import "../styles/BlogHomePage.scss";
import GlobalNavBar from "../components/GlobalNavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import PageMetaData from "../components/PageMetaData";

function ArticleCard({ image, title, content, date, blogId, isTrending }) {
    const cardClassName = isTrending ? "articleCardTrending" : "articleCard";
    return (
        <div className={cardClassName}>
            <div className="imageContainer">
                <img className="blogImage" src={image} alt={title} />
            </div>

            <div className="blogContent">
                <div className="blogContentTop">
                    <h3 className="blogTitle">{title}</h3>
                    <p className="blogDesc">{content}</p>
                </div>
                <div className="blogContentBottom">
                    <span className="blogDate">{date}</span>
                    <div className="link">
                        <Link to={`/read/blog/${blogId}`}>Read More</Link>
                        <div className="icon">
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BlogHomePage() {
    let trendingBlogs = [];
    let featuredBlogs = [];
    let recentBlogs = [];

    blogsData.forEach((blog) => {
        switch (blog.category) {
            case "trending":
                trendingBlogs.push({ ...blog, isTrending: true });
                break;
            case "featured":
                featuredBlogs.push({ ...blog, isTrending: false });
                break;
            case "recent":
                recentBlogs.push({ ...blog, isTrending: false });
                break;
            default:
                break;
        }
    });

    const renderBlogs = (blogs) => {
        return (
            <div className="blogsContainer">
                {blogs.map((blog) => (
                    <ArticleCard key={blog.id} {...blog} />
                ))}
            </div>
        );
    };

    return (
        <div className="rootBloghomepage">
            <PageMetaData
                title="Boost | Smoothest credit building experience"
                description="Discover how to secure instant personal loans without a CIBIL score. Explore insightful advice on navigating credit for newcomers and obtaining instant NBFC loans effortlessly. Learn how a lack of credit history doesn't hinder your access to quick financing solutions."
            />

            <GlobalNavBar />
            <div className="mainContainer">
                <div className="headingContainer">
                    <h1 className="heading">NEWS</h1>
                    <h1 className="heading">LETTERS</h1>
                </div>

                <div className="trending">{renderBlogs(trendingBlogs)}</div>

                <div className="featured">
                    {/* <h1 className="sectionHeading">FEATURED ARTICLES</h1> */}

                    {renderBlogs(featuredBlogs)}
                </div>

                <div className="recent">
                    {/* <h1 className="sectionHeading">RECENT ARTICLES</h1> */}

                    {renderBlogs(recentBlogs)}
                </div>
            </div>
        </div>
    );
}

export default BlogHomePage;
