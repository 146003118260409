import React from "react";
import "../styles/features.css"; // Import the CSS file
import circle_middle from "../images/circle_middle.png";
import circle_left from "../images/circle_left.png";
import circle_right from "../images/circle_right.png";
import card_middle from "../images/card_middle.png";
import card_left from "../images/card_left.png";
import card_right from "../images/card_right.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Features = () => {
    const featuresList = [
        { name: "Rebuild Credit", description: "Fast, safe and risk free." },
        {
            name: "Get Cheaper Loans",
            description: "Save upto 50% in interest payments.",
        },
        {
            name: "Bump Your Credit Score",
            description: "We only report payments that increase your score.",
        },
    ];

    return (
        <section id="features">
            <div
                className="feature-heading"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <span className="green">BOOST </span>{" "}
                <span className="white">
                    Your Financial <br></br> Life
                </span>
            </div>

            <div
                className="feature-container"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div className="feature left">
                    <img
                        className="circle"
                        src={circle_left}
                        alt="circle-left"
                    />
                    <img
                        className="card"
                        src={card_left}
                        alt={featuresList[0].name}
                    />
                </div>
                <div className="feature middle">
                    <img
                        className="circle"
                        src={circle_middle}
                        alt="circle-middle"
                    />
                    <img
                        className="card"
                        src={card_middle}
                        alt={featuresList[1].name}
                    />
                </div>
                <div className="feature right">
                    <img
                        className="circle"
                        src={circle_right}
                        alt="circle-right"
                    />
                    <img
                        className="card"
                        src={card_right}
                        alt={featuresList[2].name}
                    />
                </div>
            </div>
        </section>
    );
};

export default Features;
