import React, { useState } from "react";
import ContactNumbers from "../components/ContactNumbers";
import Messages from "../components/Messages";
import "../styles/Dashboard.scss";
import logo from "../images/Logo.svg";

const Dashboard = () => {
    const [selectedView, setSelectedView] = useState("contactNumbers"); // Default view

    return (
        <div className="rootDashboard">
            <div className="header">
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>

                <div className="buttonsContainer">
                    <button
                        className="contentButton"
                        onClick={() => setSelectedView("contactNumbers")}
                    >
                        Contact Numbers
                    </button>
                    <button
                        className="contentButton"
                        onClick={() => setSelectedView("messages")}
                    >
                        Messages
                    </button>
                </div>
            </div>

            <div className="content">
                {selectedView === "contactNumbers" && <ContactNumbers />}
                {selectedView === "messages" && <Messages />}
            </div>
        </div>
    );
};

export default Dashboard;
