import React, { useState } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/adminglogin";
import Dashboard from "./pages/dashboard";
import Main from "./pages/Main";
import BlogHomePage from "./pages/BlogHomePage";
import RenderBlogs from "./pages/renderBlogs";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(true);

    const handleLogin = (username, password) => {
        if (username === "admin" && password === "BoostNumbers") {
            setIsLoggedIn(true);
        }
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/reading" element={<BlogHomePage />} />
                <Route path="/read/blog/:blog_id" element={<RenderBlogs />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route
                    path="/login"
                    element={
                        <LoginPage
                            handleLogin={handleLogin}
                            isLoggedIn={isLoggedIn}
                        />
                    }
                />
                {isLoggedIn ? (
                    <Route path="/dashboard" element={<Dashboard />} />
                ) : (
                    <Route
                        path="/dashboard"
                        element={
                            <LoginPage
                                handleLogin={handleLogin}
                                isLoggedIn={isLoggedIn}
                            />
                        }
                    />
                )}
            </Routes>
        </Router>
    );
}

export default App;
