import React, { useState, useEffect } from "react";
import "../firebase";
import "../styles/home.css"; // Import the CSS file
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles
import { firestore } from "../firebase"; // Import Firebase services
import { collection, addDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import moment from "moment";
import cover_right from "../images/cover_right.png";
import arrow from "../images/arrow.png";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import fitty from "fitty";
import playStore from "../images/playStore.svg";

AOS.init();

const Home = () => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [count, setCount] = useState(0); // State to store the count

    useEffect(() => {
        // Fetch the count from the "count" document in Firebase when the component mounts
        const getCount = async () => {
            try {
                // set the heading font size
                if (window.innerWidth > 768) {
                    fitty("#boost-heading");
                }

                const countDocRef = doc(firestore, "count", "a"); // Replace 'your_document_id' with the actual document ID
                const countDocSnapshot = await getDoc(countDocRef);
                if (countDocSnapshot.exists()) {
                    const data = countDocSnapshot.data();
                    setCount(data.count);
                }
            } catch (error) {
                console.error("Error fetching count:", error);
            }
        };

        getCount();
    }, []);

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleGetEarlyAccess = async () => {
        if (mobileNumber.length !== 10) {
            console.log("Error number ");
            toast.error("Please Enter a valid Phone number", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
        } else {
            try {
                setCount(count + 1);
                // Update the count in Firebase by 1
                const countDocRef = doc(firestore, "count", "a"); // Replace 'your_document_id' with the actual document ID
                await updateDoc(countDocRef, {
                    count: count + 1,
                });
                // Store the mobile number in Firestore
                const userTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                const mobileNumbersRef = collection(firestore, "mobileNumbers"); // Replace 'mobileNumbers' with your desired collection name
                const newMobileNumber = {
                    number: mobileNumber,
                    date_time: moment().format("DD-MM-YYYY hh:mm:ss"),
                    timezone: userTimezone,
                };
                const docRef = await addDoc(mobileNumbersRef, newMobileNumber);

                console.log("Document written with ID: ", docRef.id);

                // Clear the input field
                setMobileNumber("");

                // Show a success toast
                toast.success("We will reach out to you soon!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000, // Close after 3 seconds
                });
            } catch (error) {
                console.error("Error storing mobile number:", error);
                // Show an error toast
                toast.error("Error storing mobile number. Please try again.", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
            // After the success toast
            window.location.href =
                "https://play.google.com/store/apps/details?id=in.boost.boost";
        }
    };

    return (
        <section id="home" data-aos="fade-up" data-aos-duration="1000">
            <div className="left-column">
                <div
                    className="heading white"
                    id="boost-heading"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    <div>
                        Build <span className="blue">Credit Score </span>
                    </div>
                    <span className="heading">with </span>
                    <span className="blue">Rent Payments</span>
                    <br></br> on Boost
                </div>
                <div className="mobile-box">
                    <img src={cover_right} alt="Contact" />
                    <img src={arrow} alt="Green Arrow" />
                    <img src={arrow} alt="Green Arrow" />
                    <img src={arrow} alt="Green Arrow" />
                    <img src={arrow} alt="Green Arrow" />
                </div>

                <h1 className="tagline">Join now to reach 850+ credit score</h1>

                <div
                    className="mobile-input-container"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                >
                    <div className="input-container">
                        <span className="indian-flag"></span>
                        <span className="country-code">+91</span>
                        <input
                            type="number"
                            className="mobile-number-input"
                            placeholder="Enter Mobile Number"
                            value={mobileNumber}
                            onChange={handleMobileNumberChange}
                        />
                    </div>{" "}
                    <div
                        className="button-container"
                        onClick={handleGetEarlyAccess}
                    >
                        <div className="play-logo">
                            <img src={playStore} alt="Company Logo" />
                        </div>
                        <button>Join the waitlist</button>
                    </div>
                    <div className="count">
                        {count} users have already signed up!
                    </div>
                </div>
            </div>

            <div className="right-column">
                <img src={cover_right} alt="Contact" />
                <img src={arrow} alt="Green Arrow" />
                <img src={arrow} alt="Green Arrow" />
                <img src={arrow} alt="Green Arrow" />
                <img src={arrow} alt="Green Arrow" />
            </div>
        </section>
    );
};

export default Home;
