// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCzU6HsZZLWtgA_FyyJgqx5U3HoNjK84uM",
    authDomain: "boost-321d9.firebaseapp.com",
    projectId: "boost-321d9",
    storageBucket: "boost-321d9.appspot.com",
    messagingSenderId: "256282601279",
    appId: "1:256282601279:web:9060e3d08d35b2777b958a",
    measurementId: "G-1DH05YKL3P",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore = getFirestore(app);
const storage = getStorage(app);
export { app, firestore, storage, analytics };
