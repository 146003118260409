import { firestore } from "../firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import "../styles/Messages.scss";

const Messages = () => {
    const [data, setData] = useState([]);

    const deleteItem = async (item) => {
        const itemRef = doc(firestore, "contacts", item.id);
        await deleteDoc(itemRef);
        setData(data.filter((dataItem) => dataItem.id !== item.id));
    };

    const fetchData = async () => {
        const contactsRef = collection(firestore, "contacts");
        const snapshot = await getDocs(contactsRef);

        const data = [];
        snapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
        });

        // Sorting the data based on date_time
        data.sort((a, b) => {
            const dateA = new Date(
                parseInt(a.date_time.substring(6, 10)), // Year
                parseInt(a.date_time.substring(3, 5)) - 1, // Month
                parseInt(a.date_time.substring(0, 2)), // Day
                parseInt(a.date_time.substring(11, 13)), // Hours
                parseInt(a.date_time.substring(14, 16)), // Minutes
                parseInt(a.date_time.substring(17, 19)) // Seconds
            );

            const dateB = new Date(
                parseInt(b.date_time.substring(6, 10)), // Year
                parseInt(b.date_time.substring(3, 5)) - 1, // Month
                parseInt(b.date_time.substring(0, 2)), // Day
                parseInt(b.date_time.substring(11, 13)), // Hours
                parseInt(b.date_time.substring(14, 16)), // Minutes
                parseInt(b.date_time.substring(17, 19)) // Seconds
            );

            return dateB - dateA;
        });

        setData(data);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="rootMessages">
            <table className="tableContainer">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Message</th>
                        <th>Date Time</th>
                        <th>Time Zone</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.message}</td>
                            <td>{item.date_time}</td>
                            <td>{item.timezone}</td>
                            <td>
                                <button
                                    className="delete"
                                    onClick={() => deleteItem(item)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Messages;
