import React, { useState, useEffect } from "react";
import logo from "../images/Logo.svg";
import "../styles/GlobalNavBar.scss";
import { Link } from "react-router-dom";

import menuClose from "../images/9dots.svg";
import menuOpened from "../images/5dots.svg";

const GlobalNavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false); // Use state to track menu state

    useEffect(() => {
        const handleBodyScroll = () => {
            if (menuOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        };

        handleBodyScroll();

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [menuOpen]);

    return (
        <nav
            className="rootGlobalNavbar"
            data-aos="fade-down"
            data-aos-duration="1000"
        >
            <div className="logo">
                <img src={logo} alt="Company Logo" />
            </div>
            <div className="nav-list">
                <ul className={menuOpen ? "menu-open" : "menu-close"}>
                    <div className="optionsContainer">
                        <li className="listItems">
                            <Link
                                className="link"
                                to="/"
                                smooth={true}
                                duration={500}
                                onClick={() => setMenuOpen(false)}
                            >
                                Home
                            </Link>
                        </li>
                        <li className="listItems">
                            <Link
                                className="link"
                                to="/"
                                smooth={true}
                                duration={500}
                                onClick={() => setMenuOpen(false)}
                            >
                                Features
                            </Link>
                        </li>
                        <li className="listItems">
                            <Link
                                className="link"
                                to="/"
                                smooth={true}
                                duration={500}
                                onClick={() => setMenuOpen(false)}
                            >
                                About Us
                            </Link>
                        </li>
                        <li className="listItems">
                            <Link
                                className="link"
                                to="/"
                                smooth={true}
                                duration={500}
                                onClick={() => setMenuOpen(false)}
                            >
                                FAQ
                            </Link>
                        </li>

                        <li className="listItems">
                            <Link
                                className="link"
                                to="/"
                                smooth={true}
                                duration={500}
                                onClick={() => setMenuOpen(false)}
                            >
                                Contact
                            </Link>
                        </li>

                        <li className="listItems">
                            <a className="link" href="/reading">
                                Blog
                            </a>
                        </li>
                    </div>
                </ul>
            </div>
            <div
                className={`menu-icon ${menuOpen ? "" : ""}`}
                onClick={() => setMenuOpen(!menuOpen)}
            >
                {menuOpen ? (
                    <img src={menuOpened} alt="Menu" />
                ) : (
                    <img src={menuClose} alt="Menu" />
                )}
            </div>
        </nav>
    );
};

export default GlobalNavBar;
