import React from "react";
import "../styles/footer.css";
import boost from "../images/Frame 98.svg";
import insta from "../images/InstagramLogo.svg";
import facebook from "../images/FacebookLogo.svg";
import linkedin from "../images/LinkedinLogo.svg";
import twitter from "../images/TwitterLogo.svg";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Footer = () => {
    return (
        <section id="footer" data-aos="fade-in" data-aos-duration="1000">
            <div className="left">
                <img src={boost} alt="boost" />
                <div className="text">
                    <p className="declaration">
                        &copy;Boost &nbsp; 2023 &nbsp; All &nbsp; rights &nbsp;
                        reserved.
                    </p>
                    <ul className="tags">
                        <li>
                            <a href="/#privacypolicy">Terms & Conditions</a>
                        </li>
                        <li>
                            <a href="/#privacypolicy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/#privacypolicy">Cookies Policy</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="socials">
                <ul>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/_boostapp_/"
                        >
                            <img src={insta} alt="" />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/profile.php?id=61552402223142"
                        >
                            <img src={facebook} alt="" />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/company/boostapp-in/"
                        >
                            <img src={linkedin} alt="" />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/_boostapp_"
                        >
                            <img src={twitter} alt="" />
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Footer;
