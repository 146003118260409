// src/components/About.js
import React from "react";
import "../styles/about.css";
// import backgroundSVG from "./images/Purple Logo.svg";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const About = () => {
    return (
        <section id="about" data-aos="fade-up" data-aos-duration="1000">
            <div className="about-text">
                <span className="blue"> BOOST </span>{" "}
                <span className="white">
                    allows you to build and improve credit score<br></br> (or
                    CIBIL score) through rent payments.{" "}
                </span>
            </div>

            <div className="about-text2">
                Pay your rent as usual through our mobile application. No credit
                card required.
            </div>
        </section>
    );
};

export default About;
