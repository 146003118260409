import React, { useState } from "react";
import { firestore } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/contact.css";
import contactimg from "../images/Contact.svg";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleInputFocus = (e) => {
        e.target.parentNode.classList.add("active");
    };

    const handleInputBlur = (e) => {
        if (e.target.value === "") {
            e.target.parentNode.classList.remove("active");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const userTimezone =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
            const contactRef = collection(firestore, "contacts");
            const newContact = {
                name: name,
                date_time: moment().format("DD-MM-YYYY hh:mm:ss"),
                timezone: userTimezone,
                email: email,
                message: message,
            };
            const docRef = await addDoc(contactRef, newContact);

            console.log("Document written with ID: ", docRef.id);

            setName("");
            setEmail("");
            setMessage("");

            toast.success("We will reach out to you soon!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
        } catch (error) {
            console.error("Error storing contact information:", error);
        }
    };

    return (
        <section id="contact">
            <div
                className="conatctimgContainer"
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div className="imageContainer">
                    <img src={contactimg} alt="Contact" />
                    <div className="imageText">
                        <p>
                            Have a question for us? We're here to help! Fill out
                            the form below and our team will get back to you as
                            soon as possible.
                        </p>
                    </div>
                </div>
            </div>
            <form
                className="formContainer"
                onSubmit={handleSubmit}
                data-aos="fade-up"
                data-aos-duration="1000"
            >
                <div className="input-group">
                    <div className="input-container">
                        <input
                            type="text"
                            value={name}
                            onChange={handleNameChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            required
                        />
                        <label className="placeholder">NAME*</label>
                    </div>
                    <div className="input-container">
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            required
                        />
                        <label className="placeholder">EMAIL*</label>
                    </div>
                    <div className="input-container">
                        <input
                            type="phone"
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            required
                        />
                        <label className="placeholder">PHONE*</label>
                    </div>
                </div>
                <div className="message-group">
                    <textarea
                        value={message}
                        onChange={handleMessageChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        required
                    />

                    <label className="placeholder">MESSAGE</label>
                    {/* <br /> */}
                    <button type="submit">SUBMIT</button>
                </div>
            </form>
        </section>
    );
};

export default Contact;
