import React, { useState, useEffect } from "react";

import Footer from "../components/footer";
import { useParams } from "react-router-dom";
import Markdown from "markdown-to-jsx";
import "../styles/RenderBlogs.scss";

export default function PrivacyPolicy() {
    let params = useParams();
    const [post, setPost] = useState("");

    useEffect(() => {
        import("../content/markdown/PrivacyPolicy.md")
            .then((res) => {
                fetch(res.default)
                    .then((res) => res.text())
                    .then((res) => setPost(res))
                    .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
    }, [params.blog_id]);

    console.log(post);

    const options = {
        overrides: {
            //for styling the markdown
            h1: {
                component: (props) => (
                    <h1 className="custom-h1">{props.children}</h1>
                ),
            },
            p: {
                component: (props) => (
                    <p className="custom-paragraph">{props.children}</p>
                ),
            },
            h3: {
                component: (props) => (
                    <h3 className="custom-h3">{props.children}</h3>
                ),
            },
            ul: {
                component: (props) => (
                    <ul className="custom-ul">{props.children}</ul>
                ),
            },

            li: {
                component: (props) => (
                    <li className="custom-li">{props.children}</li>
                ),
            },

            table: {
                component: (props) => (
                    <table className="custom-table">{props.children}</table>
                ),
            },
        },
    };

    return (
        <>
            <div className="blog">
                <div className="header"></div>
                <div className="container">
                    <Markdown options={options}>{post}</Markdown>
                </div>
            </div>
            <Footer />
        </>
    );
}
