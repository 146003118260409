import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/LoginPage.scss";
import Logo from "../images/Logo.svg";

function LoginPage({ handleLogin, isLoggedIn }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const attemptLogin = () => {
        handleLogin(username, password);
    };

    if (isLoggedIn) {
        navigate("/dashboard");
        return null; // This is a temporary placeholder to prevent rendering
    }

    return (
        <div className="rootLogin">
            <div class="form">
                <div className="logo">
                    <img src={Logo} alt="Company Logo" />
                </div>
                <div className="inputContainer">
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="loginButton" onClick={attemptLogin}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
