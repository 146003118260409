// src/Main.js
import React from "react";
import "../firebase";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import About from "../components/About";
import Features from "../components/Features";
import Contact from "../components/Contact";
import Footer from "../components/footer";
import FAQ from "../components/faqs";

import { ToastContainer } from "react-toastify";
import "../styles/Main.css";

function Main() {
    return (
        <div className="App">
            {/* <PageMetaData
                title="Boost | Smoothest credit building experience"
                description="Boost offers the smoothest credit-building experience. Elevate your credit score by paying rent through our platform and aim for a remarkable 850+ score. Rebuild credit swiftly, securely, and risk-free while enjoying up to 50% savings on loan interest payments. Discover how Boost reports only credit-boosting payments. Pay rent hassle-free via our mobile app—no credit card needed. Contact us for any queries!"
            /> */}
            <Navbar />
            <Home />
            <About />
            <Features />
            <FAQ />
            <Contact />
            <Footer />
            <ToastContainer />
        </div>
    );
}

export default Main;
